<template>
  <CreditCardTemplate
    :formFlag="formFlag"
    :hideUpload.sync="hideUpload"
    :resultFlag="resultFlag"
    :successFlag="successFlag"
    :icon_show_success.sync="icon_show_success"
    :icon_show_fail="icon_show_fail"
    :resultMessage.sync="resultMessage"
    :userId="userId"
    :paymentDepositId.sync="paymentDepositId"
  >
    <div class="form-box">
      <p>{{ $t('deposit.cc.header') }}</p>
      <DepositInfo :showInst="showInst" :noteData="noteData"></DepositInfo>
      <el-form label-position="top" :model="form" ref="form" :rules="rules" status-icon>
        <div class="form-content clearfix">
          <div class="top">
            <AccountNumber
              supportedCurrencies="all"
              @setCurrency="setCurrency"
              @setAccountNumber="setAccountNumber"
            ></AccountNumber>

            <el-form-item :label="setAmtLabel(accountCurrency, form.amount)" prop="amount">
              <numeric-input v-model="form.amount" :currency="accountCurrency" :precision="2"></numeric-input>
            </el-form-item>

            <el-form-item
              :label="$t('deposit.cc.field.fundNow')"
              prop="accountNumber"
              v-if="form.accountNumber && card.cardOptions.length > 0"
            >
              <el-select v-model="card.selected" data-testid="accountNumber">
                <el-option
                  v-for="(item, index) in card.cardOptions"
                  :key="index"
                  :value="item.id"
                  :label="setCardLabel(item)"
                  :data-testid="item.id"
                ></el-option>
                <el-option :label="$t('deposit.cc.field.newCard')" :value="0" data-testid="newCard"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="$t('deposit.cc.field.cardNum')"
              prop="masterJCBCreditCard"
              v-if="card.cardOptions.length == 0 || card.selected == '0'"
            >
              <el-input v-model="form.masterJCBCreditCard" data-testid="masterCreditCard"></el-input>
            </el-form-item>

            <el-form-item v-else :label="$t('deposit.cc.field.cardNum')" prop="oldCreditCardMiddle">
              <div class="ccNumber clearfix">
                <el-input :disabled="true" :value="form.oldCreditCardFirst" data-testid="oldCreditCardFirst"></el-input>
                <el-form-item>
                  <el-input
                    v-model="form.oldCreditCardMiddle"
                    maxlength="7"
                    data-testid="oldCreditCardMiddle"
                  ></el-input>
                </el-form-item>
                <el-input :disabled="true" :value="form.oldCreditCardLast" data-testid="oldCreditCardLast"></el-input>
              </div>
            </el-form-item>

            <el-form-item prop="nameCard" class="nameCard">
              <p class="required-label">
                <span class="required_icon">*</span>{{ $t('deposit.cc.field.nameOnCard') }}
                <img src="@/assets/images/label_icon.png" alt class="label-icon" />
                <i18n path="common.fund.infoHover" tag="hover">
                  <template v-slot:must>
                    <u>{{ $t('common.fund.must') }}</u>
                  </template>
                </i18n>
              </p>
              <el-input
                v-model="form.nameCard"
                maxlength="32"
                :disabled="card.selected != ''"
                data-testid="nameCard"
              ></el-input>
            </el-form-item>
          </div>
          <div class="bottom">
            <div class="left">
              <el-form-item :label="$t('deposit.cc.field.expDate')" prop="year" required>
                <div class="date clearfix">
                  <el-form-item prop="month" class="month fl">
                    <Month :year="form.year" v-model="form.month"></Month>
                  </el-form-item>
                  <el-form-item prop="year" class="year fr">
                    <Year v-model="form.year"></Year>
                  </el-form-item>
                </div>
              </el-form-item>

              <el-form-item :label="$t('deposit.cc.field.cvv')" class="security" prop="securityCode">
                <span class="cvvTip" v-html="$t('deposit.cc.cvvTip')"></span>
                <el-input v-model="form.securityCode" maxlength="4" data-testid="securityCode"></el-input>
              </el-form-item>
            </div>
            <div class="right">
              <img :src="require(`@/assets/images/channel/master_img.png`)" alt />
            </div>
          </div>

          <div class="bottom">
            <div class="left">
              <el-button class="btn-blue" :loading="loading" @click="submitForm()" data-testid="submit">
                {{ $t('common.button.submit') }}
              </el-button>
            </div>
            <p class="red" v-if="countryCode === twCountryCode">{{ $t('deposit.cc.tipOnlyTaiwan') }}</p>
          </div>
        </div>
      </el-form>
      <div v-html="bankHTML"></div>
    </div>
  </CreditCardTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import Month from '@/components/form/Month';
import Year from '@/components/form/Year';
import Hover from '@/components/Hover';
import CreditCardTemplate from '@/components/template/deposit/CreditCardTemplate';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import { apiCc_payment_sd } from '@/resource';
import mixin from '@/mixins/page/deposit/cc';
import countryCodeMixin from '@/mixins/countryCode';
import creditCard from '@/mixins/page/deposit/creditCard';
import { ccIsVaild } from '@/util/cc-validate';

export default {
  name: 'SdpaymentDeposit',
  components: {
    NumericInput,
    AccountNumber,
    Month,
    Year,
    Hover,
    CreditCardTemplate,
    DepositInfo
  },
  mixins: [mixin, countryCodeMixin, creditCard],
  data() {
    return {
      showInst: false,
      noteData: [
        this.$t('deposit.creditOrDebit.Sdpay.note1'),
        this.$t('deposit.creditOrDebit.Sdpay.note2'),
        this.$t('deposit.creditOrDebit.Sdpay.note3')
      ]
    };
  },
  methods: {
    submitDeposit() {
      let card = this.form.masterJCBCreditCard;
      if (this.form.masterJCBCreditCard == null || this.form.masterJCBCreditCard == '') {
        card = this.form.oldCreditCardFirst + this.form.oldCreditCardMiddle + this.form.oldCreditCardLast;
      }
      card = card.replace(/[^\d]/g, '');

      return apiCc_payment_sd({
        mt4Account: this.form.accountNumber,
        paymentMethod: 'creditcard',
        depositAmount: this.form.amount,
        cardNumber: card,
        cardHolderName: this.form.nameCard,
        expireYear: this.form.year,
        expireMonth: this.$options.filters.appendzero(this.form.month),
        cvv: this.form.securityCode,
        brand: ccIsVaild(card),
        cardExist: this.form.oldCreditCardFirst ? true : false
      });
    },
    successMethod(result) {
      if (result.data.data && result.data.code == 0) {
        window.location = result.data.data;
      } else {
        this.loading = false;
        this.errorMessage(this.$t('deposit.default.failed'));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/creditOrDebit.scss';
</style>
